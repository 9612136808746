@font-face {
  font-family: 'flowyfont';
  src: url('fonts/flowyfont.eot?pqq9nk');
  src: url('fonts/flowyfont.eot?pqq9nk#iefix') format('embedded-opentype'),
    url('fonts/flowyfont.woff2?pqq9nk') format('woff2'),
    url('fonts/flowyfont.ttf?pqq9nk') format('truetype'),
    url('fonts/flowyfont.woff?pqq9nk') format('woff'),
    url('fonts/flowyfont.svg?pqq9nk#flowyfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'flowyfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-building-o:before {
  content: '\e900';
}
.icon-building:before {
  content: '\e902';
}
.icon-cloud-download:before {
  content: '\e903';
}
.icon-user-o:before {
  content: '\e904';
}
.icon-group:before {
  content: '\e905';
}
.icon-users:before {
  content: '\e905';
}
.icon-user:before {
  content: '\e906';
}
.icon-prop_desk_screen_curved:before {
  content: '\e901';
}
.icon-flag:before {
  content: '\e9cc';
}
.icon-previous:before {
  content: '\e802';
}
.icon-next:before {
  content: '\e817';
}
.icon-map-editing:before {
  content: '\e82c';
}
.icon-user_location:before {
  content: '\e93b';
}
.icon-user_location_filled:before {
  content: '\e938';
}
.icon-skype2:before {
  content: '\e93c';
}
.icon-skype_filled:before {
  content: '\e930';
}
.icon-gears2:before {
  content: '\e93d';
}
.icon-gears_filled:before {
  content: '\e931';
}
.icon-circled_chevron_right:before {
  content: '\e932';
}
.icon-circled_chevron_right_filled:before {
  content: '\e933';
}
.icon-Center-Direction-Filled:before {
  content: '\e924';
}
.icon-Define-Location-Filled:before {
  content: '\e926';
}
.icon-wayfind1:before {
  content: '\e927';
}
.icon-arrow:before {
  content: '\e928';
}
.icon-arrow-filled:before {
  content: '\e929';
}
.icon-back:before {
  content: '\e803';
}
.icon-expand-arrow:before {
  content: '\e815';
}
.icon-collapse-arrow:before {
  content: '\e80e';
}
.icon-forward2:before {
  content: '\e819';
}
.icon-fa-chevron-left:before {
  content: '\e92a';
}
.icon-fa-chevron-down:before {
  content: '\e92b';
}
.icon-fa-chevron-up:before {
  content: '\e92d';
}
.icon-fa-chevron-right:before {
  content: '\e92e';
}
.icon-fa-caret-left:before {
  content: '\e93e';
}
.icon-fa-caret-up:before {
  content: '\e93f';
}
.icon-fa-caret-down:before {
  content: '\e940';
}
.icon-fa-caret-right:before {
  content: '\e941';
}
.icon-down:before {
  content: '\e813';
}
.icon-up:before {
  content: '\e84f';
}
.icon-left:before {
  content: '\e821';
}
.icon-right:before {
  content: '\e842';
}
.icon-resize-horizontal:before {
  content: '\e83f';
}
.icon-resize-vertical:before {
  content: '\e840';
}
.icon-direction:before {
  content: '\f125';
}
.icon-speech-bubble:before {
  content: '\e84a';
}
.icon-speech-bubble-filled:before {
  content: '\e943';
}
.icon-cancel-filled:before {
  content: '\e944';
}
.icon-calendar2:before {
  content: '\e806';
}
.icon-calendar-filled:before {
  content: '\e945';
}
.icon-calendar22:before {
  content: '\e946';
}
.icon-non-bookable1:before {
  content: '\e85e';
}
.icon-bookable1:before {
  content: '\e858';
}
.icon-compact-camera:before {
  content: '\e947';
}
.icon-compact-camera-filled:before {
  content: '\e948';
}
.icon-empty-battery:before {
  content: '\e908';
}
.icon-low-battery:before {
  content: '\e909';
}
.icon-full-battery:before {
  content: '\e949';
}
.icon-half-charged-battery:before {
  content: '\e94a';
}
.icon-empty-battery-filled:before {
  content: '\e94b';
}
.icon-full-battery-filled:before {
  content: '\e94c';
}
.icon-half-charged-battery-filled:before {
  content: '\e94d';
}
.icon-low-battery-filled:before {
  content: '\e94e';
}
.icon-hide-filled:before {
  content: '\e94f';
}
.icon-hide:before {
  content: '\e950';
}
.icon-briefcase2:before {
  content: '\e804';
}
.icon-business:before {
  content: '\e805';
}
.icon-globe2:before {
  content: '\e951';
}
.icon-globe-filled:before {
  content: '\e86d';
}
.icon-checkmark:before {
  content: '\e80a';
}
.icon-fa-check:before {
  content: '\e952';
}
.icon-checked-1:before {
  content: '\e867';
}
.icon-checked:before {
  content: '\e953';
}
.icon-checked-filled:before {
  content: '\e868';
}
.icon-attention:before {
  content: '\e801';
}
.icon-fa-info-circle:before {
  content: '\e954';
}
.icon-fa-question-circle:before {
  content: '\e955';
}
.icon-fa-exclamation-circle:before {
  content: '\e956';
}
.icon-fa-exclamation-triangle:before {
  content: '\e957';
}
.icon-ok:before {
  content: '\e838';
}
.icon-west-direction:before {
  content: '\e851';
}
.icon-center-direction:before {
  content: '\e809';
}
.icon-define-location:before {
  content: '\e810';
}
.icon-east-direction:before {
  content: '\e814';
}
.icon-south-direction:before {
  content: '\e849';
}
.icon-near-me:before {
  content: '\e836';
}
.icon-restart:before {
  content: '\e843';
}
.icon-fa-rotate-right:before {
  content: '\e958';
}
.icon-fa-rotate-left:before {
  content: '\e959';
}
.icon-fa-times:before {
  content: '\e95a';
}
.icon-info-filled:before {
  content: '\e86f';
}
.icon-minus:before {
  content: '\e832';
}
.icon-info2:before {
  content: '\e81e';
}
.icon-fa-minus2:before {
  content: '\e95b';
}
.icon-logout-rounded-down:before {
  content: '\e826';
}
.icon-logout-rounded-up:before {
  content: '\e827';
}
.icon-cancel:before {
  content: '\e80b';
}
.icon-logout-rounded:before {
  content: '\e829';
}
.icon-shutdown:before {
  content: '\e848';
}
.icon-login-rounded-down:before {
  content: '\e824';
}
.icon-login-rounded-right:before {
  content: '\e825';
}
.icon-plus2:before {
  content: '\e83d';
}
.icon-fa-plus:before {
  content: '\e95c';
}
.icon-synchronize:before {
  content: '\e84c';
}
.icon-mute:before {
  content: '\e835';
}
.icon-low-volume:before {
  content: '\e82b';
}
.icon-medium-volume:before {
  content: '\e82e';
}
.icon-high-volume:before {
  content: '\e81c';
}
.icon-fa-search:before {
  content: '\e95d';
}
.icon-search2:before {
  content: '\e844';
}
.icon-zoom-out:before {
  content: '\e853';
}
.icon-zoom-in:before {
  content: '\e852';
}
.icon-reading-confirmation:before {
  content: '\e83e';
}
.icon-new-post:before {
  content: '\e839';
}
.icon-deleted-message:before {
  content: '\e811';
}
.icon-message:before {
  content: '\e831';
}
.icon-urgent-message:before {
  content: '\e850';
}
.icon-sent:before {
  content: '\e845';
}
.icon-paper-plane2:before {
  content: '\e83a';
}
.icon-phone-disconnected:before {
  content: '\e846';
}
.icon-phone2:before {
  content: '\e847';
}
.icon-prop_phone:before {
  content: '\e862';
}
.icon-delete:before {
  content: '\e812';
}
.icon-multiply:before {
  content: '\e834';
}
.icon-show-all:before {
  content: '\e863';
}
.icon-more:before {
  content: '\e833';
}
.icon-menu-2:before {
  content: '\e82f';
}
.icon-menu:before {
  content: '\e830';
}
.icon-list2:before {
  content: '\e822';
}
.icon-layers:before {
  content: '\e823';
}
.icon-sheets:before {
  content: '\e84b';
}
.icon-floors:before {
  content: '\e85b';
}
.icon-map-marker22:before {
  content: '\e82d';
}
.icon-map2:before {
  content: '\e84d';
}
.icon-filter2:before {
  content: '\e816';
}
.icon-clear-filters:before {
  content: '\e80d';
}
.icon-marker:before {
  content: '\e84e';
}
.icon-location1:before {
  content: '\e800';
}
.icon-fa-map-marker:before {
  content: '\e95e';
}
.icon-geo-fence:before {
  content: '\e81a';
}
.icon-building2:before {
  content: '\e80c';
}
.icon-department:before {
  content: '\e81b';
}
.icon-home2:before {
  content: '\e81d';
}
.icon-street-view2:before {
  content: '\e854';
}
.icon-circled-user-male:before {
  content: '\e81f';
}
.icon-gender-neutral-user:before {
  content: '\e855';
}
.icon-number-of-seats:before {
  content: '\e865';
}
.icon-number-of-seats-1:before {
  content: '\e875';
}
.icon-colleague:before {
  content: '\e859';
}
.icon-invisible:before {
  content: '\e85c';
}
.icon-visible:before {
  content: '\e869';
}
.icon-invisible-1:before {
  content: '\e870';
}
.icon-lock2:before {
  content: '\e85a';
}
.icon-unlock2:before {
  content: '\e86a';
}
.icon-christmas-star:before {
  content: '\e86b';
}
.icon-hanger:before {
  content: '\e86e';
}
.icon-hearts:before {
  content: '\e871';
}
.icon-iphone:before {
  content: '\e872';
}
.icon-print2:before {
  content: '\e874';
}
.icon-settings:before {
  content: '\e877';
}
.icon-shake-phone:before {
  content: '\e878';
}
.icon-tv2:before {
  content: '\e879';
}
.icon-d-view:before {
  content: '\e87a';
}
.icon-d-view2:before {
  content: '\e87b';
}
.icon-prop_sofa:before {
  content: '\e87c';
}
.icon-desk2:before {
  content: '\e87d';
}
.icon-desk1:before {
  content: '\e95f';
}
.icon-tele2-desk:before {
  content: '\e960';
}
.icon-prop_proj:before {
  content: '\e87e';
}
.icon-room1:before {
  content: '\e87f';
}
.icon-tele2-room:before {
  content: '\e961';
}
.icon-prop_desk_screen_dual:before {
  content: '\e963';
}
.icon-prop_screen:before {
  content: '\e880';
}
.icon-prop_desk_mac:before {
  content: '\e962';
}
.icon-prop_desk_screen_single:before {
  content: '\e965';
}
.icon-prop_desk_ergonomic:before {
  content: '\e966';
}
.icon-prop_video:before {
  content: '\e881';
}
.icon-error-report-triangle:before {
  content: '\e882';
}
.icon-flowscape-logo:before {
  content: '\e883';
}
.icon-fa-spinner:before {
  content: '\e967';
}
.icon-edit-2:before {
  content: '\e907';
}
.icon-profile_white:before {
  content: '\e90a';
}
.icon-calendar_white:before {
  content: '\e90b';
}
.icon-geo_fence_white:before {
  content: '\e90c';
}
.icon-clock:before {
  content: '\e90d';
}
.icon-desk_locker:before {
  content: '\e90e';
}
.icon-desk_parking:before {
  content: '\e90f';
}
.icon-desk_parking_electric:before {
  content: '\e910';
}
.icon-space:before {
  content: '\e911';
}
.icon-user-check:before {
  content: '\e912';
}
.icon-binoculars:before {
  content: '\e985';
}
.icon-search:before {
  content: '\e986';
}
.icon-cog:before {
  content: '\e994';
}
.icon-magic-wand:before {
  content: '\e997';
}
.icon-aid-kit:before {
  content: '\e998';
}
.icon-fire:before {
  content: '\e9a9';
}
.icon-lab:before {
  content: '\e9aa';
}
.icon-list21:before {
  content: '\e9bb';
}
.icon-tree:before {
  content: '\e9bc';
}
.icon-reset:before {
  content: '\e843';
}
